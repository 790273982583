export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UWAGA: bez unikatowego kodu zwrotnego nie można zidentyfikować paczki."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer konta bankowego zaczyna się od %s (przykład%s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pamiętaj, aby umieścić swój unikalny numer reklamacji do produktu w opakowaniu. Twój unikalny numer reklamacji to"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentarz"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uszkodzony produkt"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powitania"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cześć!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje nie są wymagane"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma powodu"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po otrzymaniu zwrotu w naszych biurach na adres podany w formularzu reklamacji zostanie wysłany e-mail, na który poinformujemy Cię o najnowszych aktualizacjach procesu zwrotu."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer zamówienia"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatne na naszej stronie internetowej, a nie z usługi dostawy/kurier"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakość produktu"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana produktu"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedura zwrotu produktu"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces zwrotu produktu"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód reklamacji"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZWROT"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz zwrotu"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot produktu jest szybki i łatwy"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot produktu jest szybki i łatwy"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz rodzaj reklamacji"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij paczkę do"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesyłaj paczkę na następujący adres:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś poszło nie tak, spróbuj ponownie później lub skontaktuj się z naszym wsparciem"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij formularz"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer konta bankowego zaczyna się od %s (przykład%s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedura zwrotu została właśnie przekazana na Twój adres e-mail:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wraz z produktem, który ma zostać zwrócony, wprowadź swój unikalny kod zwrotny w opakowaniu.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj płatności"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po otrzymaniu przesyłki w naszym oddziale zostaniesz poinformowany o przebiegu reklamacji na swój adres e-mail, który podałeś przy składaniu formularza reklamacyjnego"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy produkt"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewłaściwa ilość"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz wydrukować już wypełnione informacje pomocne w zwrocie produktu"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesłano już żądanie zwrotu. Bądź cierpliwy"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymałeś plik PDF do procedury zwrotu w załączniku."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój unikalny numer zwrotny to"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miasto"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer skargi"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przykład"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrukcje"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reciever"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadawca"])}
}